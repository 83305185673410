/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    &:hover {
      border-color: var(--#{$prefix}border-color);
    }
  }
  .css-t3ipsp-control {
    background-color: var(--#{$prefix}gray-200);
    border-color: var(--#{$prefix}border-color);
    box-shadow: none;
    &:hover {
      border-color: var(--#{$prefix}border-color) !important;
    }
  }
  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}gray-200);
    .css-10wo9uf-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-200);
      &:active {
        background-color: var(--#{$prefix}gray-200);
      }
    }
    .css-d7l1ni-option {
      cursor: pointer;
      background-color: var(--#{$prefix}gray-100);
    }
  }
  .css-1p3m7a8-multiValue {
    background-color: var(--#{$variable-prefix}light);
  }
  .css-wsp0cs-MultiValueGeneric {
    color: $heading-color;
  }
  .css-12jo7m5 {
    color: $heading-color;
  }
  .css-6j8wv5-Input,
  .css-qc6sy-singleValue {
    color: $heading-color;
  }

  .css-1dimb5e-singleValue {
    color: var(--vz-body-color);
  }
}

.marketplace-swiper .swiper-button-next,
.marketplace-swiper .swiper-button-prev {
  top: 24px;
}

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 22px;
  line-height: 2;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 35s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

// Data Table

.kVrXuC,
.dwPlXY,
.gKbhqU,
.iSAVrt {
  background-color: $table-bg !important;
  color: $table-color !important;
}

.fyrdjl {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.54) !important;
}

.fyrdjl:disabled {
  color: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
  fill: rgba(var(--#{$prefix}body-color-rgb), 0.18) !important;
}

.iSAVrt {
  background-color: var(--#{$prefix}card-bg-custom) !important;
  border-bottom-color: $table-border-color !important;
}

.dwPlXY:not(:last-of-type) {
  border-bottom-color: $table-border-color !important;
}

.icon-edit-tab {
  line-height: 1.2;
  padding: 0 5px;
  margin-left: 5px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.45);

  &:hover {
    font-weight: 500;
    color: #111 !important;
  }
}
.ant-tabs-tab-remove {
  margin-left: 3px !important;
}

.execution-sidebar {
  position: relative;
  background-color: var(--#{$prefix}card-bg-custom);
  height: calc(100vh - 300px);
  overflow-y: auto;

  @media (min-width: 992px) {
    width: 50%;
  }

  @media (max-width: 991.98px) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    max-width: 100%;
    z-index: 1003;
    box-shadow: $box-shadow-lg;
    transform: translateX(-100%);
    visibility: hidden;
    height: 100vh;

    &.menubar-show {
      visibility: visible;
      transform: none;
    }
  }
}

.execution-action {
  padding: 5px;
  margin-bottom: 18px;
  position: relative;
  text-align: center;
  z-index: 22;
  background: #fff;
  cursor: pointer;
}
.execution-action-after {
  &::after {
    content: "";
    border-left: 2px dashed var(--vz-border-color);
    position: absolute;
    height: 21px;
    left: 50%;
    top: 30px;
    z-index: 1;
  }
}
.execution-action-border {
  border: 1px solid #cccc;
  border-radius: 3px;
}

.nav-tabs::-webkit-scrollbar {
  height: 5px;
  border-radius: 8px;
  cursor: pointer;
}

/* Track */
.nav-tabs::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.nav-tabs::-webkit-scrollbar-thumb {
  background: #fff;
}

.nav-tabs:hover {
  &::-webkit-scrollbar-thumb {
    background: #ccccccde;
  }
}

/* Handle on hover */
.nav-tabs::-webkit-scrollbar-thumb:hover {
  background: #ccccccde;
}

.icon-copy {
  position: absolute;
  font-size: 21px;
  margin-left: 32px;
  display: none;
  z-index: 5;
}
.hover-title-tree {
  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 22px;
    z-index: 1;
  }
  &:hover {
    & .icon-copy {
      display: inline-block;
    }
  }
}

.ant-mention-editor-wrapper .public-DraftEditor-content div > span:not([data-offset-key]) {
  background-color: red;
}

.fullscreen {
  height: 100%;
  position: relative;
  background: #fff;
}
.icon-fullscreen {
  position: absolute;
  z-index: 222;
  cursor: pointer;
  // top: 5px;
  // left: 10px;
}
.ant-table-cell {
  padding: 5px !important;
  font-family: "Roboto", sans-serif;
}

.array-item-toolbox > .btn-group {
  display: block !important;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

.skeleton-box {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    min-height: 1em;
    cursor: wait;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: linear-gradient(to right, #dddddd, #ffffff 50%, #dddddd);
    // animation: skeletonLoading 2s infinite alternate;

    animation: skeleton-loading 1s linear infinite alternate;
  }

  &::before {
    background-color: #dddddd;
    content: "";
    cursor: wait;
    min-height: 1em;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 8%, 85%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
@keyframes skeletonLoading {
  from {
    left: -50%;
  }
  to {
    left: 50%;
  }
}

.ant-pagination-options-size-changer {
  display: none !important;
}

.delete-select {
  font-size: 19px;
  line-height: 20px;
  margin: 0 10px;
  cursor: pointer;
  &:hover {
    color: #f7b84b;
  }
}

.form-group > .control-label {
  margin-bottom: 2px;
  margin-top: 10px;
}

.rti--container {
  align-items: center;
  background: var(--vz-card-cap-bg);
  border: 1px solid var(--vz-border-color);
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  line-height: 1.4;
  padding: 0.15rem 0.25rem;
}
.rti--input {
  background: var(--vz-card-cap-bg);
  color: var(--vz-body-color);
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
}
.rti--tag {
  align-items: center;
  background: #edf2f7;
  color: #212529;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  padding: 0.15rem 0.25rem;
}

.status {
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  vertical-align: middle;
  line-height: 18px;
  padding: 2px 7px;
  border-radius: 6px;
  font-size: 13px;

  &-active {
    border: 1px solid #9fedcf;
    background-color: #f3fcf9;
    color: #28a745;
  }
  &-pending {
    border: 1px solid #ffb8b8;
    background-color: #fff6f6;
    color: #db3700;
  }
}

.style-icon-filter {
  font-size: 17px;
  margin: 0 6px;
  cursor: pointer;
  line-height: 20px;
}

.disable-icon {
  pointer-events: none;
  opacity: 0.7;
}
.ant-steps-item {
  height: 65px !important;
}
.ant-steps-icon {
  top: -2.5px !important;
}
.ant-steps .ant-steps-item-active .ant-steps-item-icon {
  background-color: rgba(0, 0, 0, 0.06);
  border-color: transparent;
}
.ant-steps .ant-steps-item-active .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.65);
}
.ant-steps .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps .ant-steps-item-error .ant-steps-item-icon {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
}
.ant-steps .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}

.item-content {
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 3px;
  background-color: #fff;
  height: 500px;
}

.title-view-print {
  color: #919191;
  font-size: 28px;
  font-weight: 700;
}

.table-key {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: #212b35;
  font-size: 14px;

  & tr {
    height: 50px;
    & th {
      padding: 20px 8px 0;
      border-bottom: 1px solid #ebeef0;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: #fff;
    }
    & td {
      padding: 6px 8px;
      vertical-align: middle;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 120px;
      background: #fff;
      line-height: 1.6;
      border-bottom: 1px solid #ebeef0;
    }
  }
}

.ck.ck-editor__editable_inline {
  // border: 1px solid #111 !important;
  height: 465px;
  overflow-y: auto;
  padding: 0 20px !important;
}
.ck.ck-toolbar__items {
  border: 1px solid transparent !important;
}
.ck.ck-balloon-panel {
  z-index: 9999999 !important;
}

.custom-popover {
  min-width: 500px;
}

.reset-package {
  position: absolute;
  right: 24px;
  top: 8px;
  color: red;
  display: flex;
  align-items: center;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: var(--vz-card-bg);
}

.wrapper-header-table {
  overflow-x: hidden;
  position: absolute;
  top: 0px;
  z-index: 1000;
  left: 0px;
  right: 0px;
  height: 55px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 5px;
}
.ant-table-cell {
  min-width: 100px;
  max-width: 250px;
}
.ant-table-sticky-scroll-bar {
  display: none;
}
.is-invalid > input {
  border-color: #f06548;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f06548%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f06548%27 stroke=%27none%27/%3e%3c/svg%3e");
}

.ant-picker {
  width: 100%;
  padding: 0.5rem 0.9rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--vz-body-color);
  background-color: var(--vz-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--vz-input-border);
  appearance: none;
  border-radius: 0.25rem;

  .ant-picker-input > input {
    font-size: 0.85rem;
  }

  &:hover {
    border-color: var(--vz-input-border) !important;
  }
}
.ant-picker-focused.ant-picker {
  color: var(--vz-body-color) !important;
  background-color: var(--vz-input-bg) !important;
  border-color: var(--vz-input-focus-border) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(247, 184, 75, 0.25) !important;
}

.text-column {
  width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.warper-add-avatar {
  width: 32px;
  height: 32px;
  position: relative;
  border: 1px #ccc dashed;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warper-add {
  position: absolute;
  bottom: 2px;
  right: 0;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-radius: 50%;
  color: white;
}

.close-avatar:hover {
  background: red;
}

.close-avatar {
  width: 18px;
  height: 18px;
  background: #ccc;
  border-radius: 50%;
  right: -2px;
  top: -5px;
  z-index: 222;
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 2px solid #fff;
}

.warper-select-avatar:hover .close-avatar {
  display: flex;
}

// .process-scroll {
//   height: calc(100vh - 420px);
//   overflow-x: hidden;
//   overflow-y: scroll;
//   // -webkit-mask-image: linear-gradient(to top, transparent, black), linear-gradient(to left, transparent 4px, black 4px);
//   // -webkit-mask-size: 100% 20000px;
//   // -webkit-mask-position: left bottom;
//   // transition: mask-position 0.3s, -webkit-mask-position 0.3s;
// }

// .process-scroll::-webkit-scrollbar {
//   width: 4px;
//   height: 20px;
//   border-radius: 4px;
// }
// // .process-scroll:hover {
// //   -webkit-mask-position: left top;
// // }
// /* Track */
// .process-scroll::-webkit-scrollbar-track {
//   background: transparent;
// }

// /* Handle */
// .process-scroll::-webkit-scrollbar-thumb {
//   background: transparent;
// }

// /* Handle on hover */
// .process-scroll::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

// .process-scroll:hover {
//   &::-webkit-scrollbar-thumb {
//     background-color: #888;
//   }
// }

.rti--container {
  width: 100% !important;
}

.is-invalid > .rti--container {
  border-color: #f06548 !important;
}

.is-invalid > .rti--container:focus-within {
  border-color: #f06548 !important;
  box-shadow: none !important;
}

.is-invalid > .invalid-feedback {
  display: block;
}

.ant-tree-treenode {
  align-items: center !important;
}
.ant-tree-draggable-icon {
  opacity: 1 !important;
}

.react-horizontal-scrolling-menu--wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  display: flex;
  overflow: hidden;
  height: 100%;
  gap: 0.25rem !important;
}

.react-horizontal-scrolling-menu--scroll-container {
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  gap: 0.25rem !important;
}

.react-horizontal-scrolling-menu--item {
  height: 100%;
}

.react-horizontal-scrolling-menu--arrow-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.react-horizontal-scrolling-menu--arrow-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.table-responsive {
  max-height: 100%;
  overflow: auto;
  thead tr:nth-child(1) th {
    --vz-bg-opacity: 1;
    background-color: rgba(var(--vz-light-rgb), var(--vz-bg-opacity)) !important;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

.summary-orders-icon {
  border-radius: var(--Spacing-500, 16px);
}

.link-custom {
  color: var(--vz-body-color);
  &:hover {
    color: var(--vz-link-color);
  }
}

.pos-header button,
.pos-header button:hover {
  color: white;
}

.pos-header {
  .dropdown button {
    color: var(--vz-dropdown-link-color);
  }
  .dropdown button:hover {
    color: var(--vz-dropdown-link-hover-color);
  }
}

.ant-dropdown-menu {
  height: 20vh;
  overflow-y: scroll;
  box-shadow: none !important;
}
